import { Button } from "@mantine/core";
import React from "react";

interface Props {
  name?: string;
  disabled?: boolean;
  onDownload: () => void;
}

export default function ExportCSVButton(props: Props) {
  return (
    <Button
      disabled={props.disabled ? props.disabled : false}
      color="yellow"
      onClick={props.onDownload}
    >
      { "Export CSV By Current Data"}
    </Button>
  );
}
