import React, { useCallback, useEffect, useState } from "react";
import HorizontalBarsContainer from "../../Components/analysis/HorizontalBarsContainer";
import LeaderboardCard from "../../Components/analysis/LeaderboardCard";
import LeaderboardContainer from "../../Components/analysis/LeaderboardContainer";
import SmallSquare from "../../Components/analysis/SmallSquare";
import VerticalBars from "../../Components/analysis/VerticalBars";
import { getMethod } from "../../utilities/fetchMethod";
import {
  EditProfilesInfo,
  initialProfileinfo,
  OwnerProfile,
  ProfilesInfo,
} from "../../utilities/models";
import { useLocation } from "react-router-dom";
import ConnectBoardContainer from "../../Components/analysis/ConnectBoardContainer";
import AnalysisNumber from "../../Components/analysis/AnalysisNumber";

export interface pushProfile {
  isAppUser: boolean;
  profile_first_name: string;
  profile_last_name: string;
  profile_title: string;
  company_name: string;
  email: string;
  phone_number: string;
  icon_url: string;
  username: string;
}

export default function AnalysisHomePage() {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [loading, setLoading] = useState(true);
  const [profileList, setProfileList] = useState([initialProfileinfo]);
  const [buildedProfileList, setBuildedProfileList] = useState<
    {
      image: string | null | undefined;
      title: string;
      subtitle: string;
      id: number;
      up: boolean;
    }[]
  >([]);
  const [buildedConnectonsProfileList, setBuildedConnectonsProfileList] =
    useState<
      {
        corporateProfile: EditProfilesInfo;
        connectProfiles: pushProfile[];
      }[]
    >([]);
  let { state } = useLocation();
  const [analysisNumber, setanalysisNumber] = useState<
    { title: string; number: number }[]
  >([]);

  useEffect(() => {
    console.log("analysisNumber", analysisNumber);
  }, [analysisNumber]);

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------



  const fetchProfileConnectData = useCallback(async () => {
    setLoading(true);
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/connect/${encodeURI(
        String(state)
      )}`,
      _getMethod
    );
    const result: {
      corporateProfile: EditProfilesInfo;
      connectProfiles: pushProfile[];
    }[] = await res.json();
    setBuildedConnectonsProfileList(result);
    console.log("ConnectonsProfileList ",result);
    
    setLoading(false);
  }, [state]);

  //fetch analysis Number
  const fetchAnalysisNumber = useCallback(async () => {
    console.log(state);

    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/analysisNumber/${encodeURI(
        String(state)
      )}`,
      _getMethod
    );
    const result = await res.json();
    console.log("result", result);
    setanalysisNumber(result);
  }, [state]);

  //-------------------------------------------------------------------------------------------
  // useEffects
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    // fetchAll();
    fetchProfileConnectData();
    fetchAnalysisNumber();
  }, [state]);

  //-------------------------------------------------------------------------------------------
  // components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <div>
        

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {analysisNumber &&
            analysisNumber.map((item, index) => (
              <AnalysisNumber
                title={item.title}
                number={item.number}
                key={index}
              />
            ))}
        </div>

        <div style={{ display: "flex" }}>
          {buildedConnectonsProfileList && (
            <ConnectBoardContainer
              title={"Profile Connections "}
              connectonDatas={buildedConnectonsProfileList}
              loading={loading}
            />
          )}
        </div>
      </div>
    </>
  );
}
