import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Tabs,
  TextInput,
  Textarea,
  MultiSelect,
  Divider,
  Alert,
} from "@mantine/core";
import { useRecoilState } from "recoil";
import produce from "immer";
import EditButton from "../Buttons/EditButton";
import dayjs from "dayjs";
import {
  getMethod,
  patchMethod,
  patchMethodwithFormData,
} from "../../utilities/fetchMethod";
import { EventSubtags, EventTags } from "../../utilities/models";
import { editingProfileState } from "../../recoil_state";
import ImageViewAndEdit from "../ImageViewAndEdit";
import { useLocation } from "react-router-dom";

var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);

interface EventPreviewInfoProps {
  headName: string;
}

export default function EventInfo({ headName }: EventPreviewInfoProps) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------

  const [editingEvent, setEditingEvent] = useRecoilState(editingProfileState);
  const [eventTags, setEventTags] = useState<EventTags[]>();
  const [eventSubtags, setEventSubtags] = useState<EventSubtags[]>();
  const [tags, setTags] = useState<string[]>();
  const [subtags, setSubtags] = useState<string[]>();
  const [filteredSubtags, setfilteredSubtags] =
    useState<{ value: string; label: string }[]>();
  const { state } = useLocation();
  const [error, setError] = useState<{ color: string; message: string }>({
    color: "red",
    message: "",
  });

  // file related
  const [fileInputValue, setFileInputValue] = useState<
    { type: string; file: File }[]
  >([]);

  const [base64InputValue, setBase64InputValue] = useState<
    { type: string; base64: string }[]
  >([]);

  const bannerRef = useRef<any>();
  const iconRef = useRef<any>();

  //-------------------------------------------------------------------------------------------
  // save function
  //-------------------------------------------------------------------------------------------

  async function save() {
    setError({ color: "red", message: "" });
    if (
      !editingEvent.profile.eventSection.event_name ||
      !editingEvent.profile.eventSection.event_location ||
      !editingEvent.profile.eventSection.about_us
    ) {
      return;
    }
    const formData = new FormData();

    //  image =============================================
    if (base64InputValue) {
      for (const item of base64InputValue) {
        const blob = await fetch(item.base64).then((r) => r.blob());
        formData.append(item.type, blob);
      }
    }

    formData.append("profileInfo", JSON.stringify({}));

    //  fetch profile info to backend =============================================
    const _patchMethodwithFormData = await patchMethodwithFormData(formData);
    const profileRes = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/profile/${encodeURI(
        String(editingEvent.profile.id)
      )}`,
      _patchMethodwithFormData
    );

    const profileResult = await profileRes.json();
    if (profileResult.message === "success") {
      if (base64InputValue.length !== 0) {
        setBase64InputValue([]);
        bannerRef.current.removeImage();
        iconRef.current.removeImage();
      }
    } else {
      setError({
        color: "red",
        message: "Something wrong happened, please try again.",
      });
      if (base64InputValue.length !== 0) {
        setBase64InputValue([]);
        bannerRef.current.removeImage();
        iconRef.current.removeImage();
      }
      return;
    }
    //  fetch eventInfo info to backend =============================================

    const body = {
      event_name: editingEvent.profile.eventSection.event_name,
      about_us: editingEvent.profile.eventSection.about_us,
      event_location: editingEvent.profile.eventSection.event_location,
      tags: editingEvent.event_tags,
      subtags: editingEvent.event_subtags,
    };

    const _patchMethod = await patchMethod(body);
    const eventRes = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/events/${encodeURI(
        String(editingEvent.profile.eventSection.id)
      )}`,
      _patchMethod
    );
    const eventResult = await eventRes.json();
    if (eventResult.message === "success") {
      const _getMethod = await getMethod();
      // get the profile result
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/events/profile/${encodeURI(
          String(editingEvent.profile.id)
        )}`,
        _getMethod
      );
      const profileResult = await res.json();

      if (res.ok) {
        setEditingEvent(profileResult);
        window.history.replaceState(
          { key: window.history.state.key, usr: editingEvent },
          ""
        );
        setError({
          color: "green",
          message: "Event information successfully saved!",
        });
      } else {
        setError({
          color: "red",
          message: "Something wrong happened, please try again.",
        });
      }
    } else {
      setError({
        color: "red",
        message: "Something wrong happened, please try again.",
      });
    }
  }

  //-------------------------------------------------------------------------------------------
  // tags related
  //-------------------------------------------------------------------------------------------

  //setting selector of subtags
  function buildLabels(tags: EventTags[]) {
    let buildedArray = [];

    for (const tag of tags) {
      buildedArray.push({
        value: String(tag.id),
        label: tag.event_tag,
      });
    }
    return buildedArray;
  }

  //setting building selected tags
  function buildTags(tags: any) {
    if (tags === null) {
      return [];
    }
    let buildedArray = [];
    for (const tag of tags) {
      buildedArray.push(String(tag.id));
    }
    return buildedArray;
  }

  //building the subtag list
  const buildSubtagList = useCallback(() => {
    let filtered = [];
    for (const tag of tags!) {
      for (const eventSubtag of eventSubtags!) {
        if (eventSubtag.event_tag.id === parseInt(tag)) {
          filtered.push({
            value: String(eventSubtag.id),
            label: eventSubtag.event_subtag,
            group: eventSubtag.event_tag.event_tag,
          });
        }
      }
    }
    return filtered;
  }, [tags, eventSubtags]);

  //fetching event tags
  const fetchEventTags = useCallback(async () => {
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/tags/findEventTagsAndEventSubTags`,
      _getMethod
    );
    const result = await res.json();
    if (res.ok) {
      setEventTags(result.eventTags);
      setEventSubtags(result.eventSubTags);
      setTags(buildTags(state.event_tags));
      setSubtags(buildTags(state.event_subtags));
    } else {
      console.log("Get findEventTagsAndEventSubTags Error");
    }
  }, []);

  //-------------------------------------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    // get tags
    fetchEventTags();
  }, [fetchEventTags]);

  useEffect(() => {
    if (tags && tags.length !== 0) {
      setfilteredSubtags(buildSubtagList());
    }
  }, [tags, buildSubtagList, setfilteredSubtags]);

  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <Tabs.Panel
      value={headName}
      pt="xs"
      style={{ padding: 10, marginBottom: 25, overflow: "auto" }}
    >
      {/* //============================================================================== */}
      {/* //event name      ============================================================== */}
      {/* //============================================================================== */}
      <TextInput
        placeholder="Event Name"
        label="Event Name"
        withAsterisk
        value={
          editingEvent.profile.eventSection.event_name
            ? editingEvent.profile.eventSection.event_name
            : ""
        }
        onChange={(event) => {
          setEditingEvent(
            produce((draftState) => {
              draftState.profile.eventSection.event_name =
                event.currentTarget.value;
            })
          );
        }}
      />
      {/* //============================================================================== */}
      {/* //event location ============================================================== */}
      {/* //============================================================================== */}
      <TextInput
        placeholder="Event Location"
        label="Event Location"
        withAsterisk
        value={
          editingEvent.profile.eventSection.event_location
            ? editingEvent.profile.eventSection.event_location
            : ""
        }
        onChange={(event) =>
          setEditingEvent(
            produce((draftState) => {
              draftState.profile.eventSection.event_location =
                event.currentTarget.value;
            })
          )
        }
      />
      <>
        {/* //============================================================================== */}
        {/* //About us        ============================================================== */}
        {/* //============================================================================== */}
        <Textarea
          placeholder="About us"
          label="About us"
          maxLength={2600}
          withAsterisk
          value={
            editingEvent.profile.eventSection.about_us
              ? editingEvent.profile.eventSection.about_us
              : ""
          }
          onChange={(event) =>
            setEditingEvent(
              produce((draftState) => {
                draftState.profile.eventSection.about_us =
                  event.currentTarget.value;
              })
            )
          }
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 10,
          }}
        >
          {editingEvent.profile.eventSection.about_us?.length
            ? editingEvent.profile.eventSection.about_us.length
            : 0}{" "}
          / 2600 characters
        </div>
      </>
      {/* //============================================================================== */}
      {/* //profile banner============================================================== */}
      {/* //============================================================================== */}
      <Divider />
      <div style={{ paddingBlock: 10, marginTop: 10 }}>
        <ImageViewAndEdit
          src={
            editingEvent.profile.banner_url
              ? editingEvent.profile.banner_url
              : null
          }
          type={"banner"}
          ref={bannerRef}
          title={"profile banner"}
          onRemove={() => {
            setEditingEvent(
              produce((draftState) => {
                draftState.profile.banner_url = null;
              })
            );

            setFileInputValue(
              produce((draftState) => {
                draftState.splice(
                  draftState.map((item) => item.type).indexOf("profile_banner"),
                  1
                );
              })
            );
            setBase64InputValue(
              produce((draftState) => {
                draftState.splice(
                  draftState.map((item) => item.type).indexOf("profile_banner"),
                  1
                );
              })
            );
          }}
          imageBase64={function base64(base64: string): void {
            setEditingEvent(
              produce((draftState) => {
                draftState.profile.banner_url = base64;
              })
            );

            const index = base64InputValue.findIndex(
              (item) => item.type === "profile_banner"
            );
            if (index !== -1) {
              setBase64InputValue(
                produce((draftState) => {
                  draftState[index].base64 = base64;
                })
              );
            } else {
              setBase64InputValue(
                produce((draftState) => {
                  draftState.push({ type: "profile_banner", base64: base64 });
                })
              );
            }
          }}
          imageFile={function imageFile(imageFile: File): void {
            const index = fileInputValue.findIndex(
              (item) => item.type === "profile_banner"
            );
            if (index !== -1) {
              setFileInputValue(
                produce((draftState) => {
                  draftState[index].file = imageFile;
                })
              );
            } else {
              setFileInputValue(
                produce((draftState) => {
                  draftState.push({ type: "profile_banner", file: imageFile });
                })
              );
            }
          }}
        />
      </div>

      {/* //============================================================================== */}
      {/* //profile icon    ============================================================== */}
      {/* //============================================================================== */}
      <div style={{ paddingBlock: 10, marginBottom: 10 }}>
        <ImageViewAndEdit
          src={editingEvent.profile?.icon_url}
          type={"icon"}
          ref={iconRef}
          title={"profile icon"}
          onRemove={() => {
            setEditingEvent(
              produce((draftState) => {
                draftState.profile.icon_url = null;
              })
            );
            setFileInputValue(
              produce((draftState) => {
                draftState.splice(
                  draftState.map((item) => item.type).indexOf("profile_icon"),
                  1
                );
              })
            );

            setBase64InputValue(
              produce((draftState) => {
                draftState.splice(
                  draftState.map((item) => item.type).indexOf("profile_icon"),
                  1
                );
              })
            );
          }}
          imageBase64={function (base64: string): void {
            setEditingEvent(
              produce((draftState) => {
                draftState.profile.icon_url = base64;
              })
            );

            const index = base64InputValue.findIndex(
              (item) => item.type === "profile_icon"
            );
            if (index !== -1) {
              setBase64InputValue(
                produce((draftState) => {
                  draftState[index].base64 = base64;
                })
              );
            } else {
              setBase64InputValue(
                produce((draftState) => {
                  draftState.push({ type: "profile_icon", base64: base64 });
                })
              );
            }
          }}
          imageFile={function (imageFile: File): void {
            setFileInputValue(
              produce((draftState) => {
                draftState.push({ type: "profile_icon", file: imageFile });
              })
            );
          }}
        />
      </div>

      {/* //============================================================================== */}
      {/* //tags            ============================================================== */}
      {/* //============================================================================== */}

      {eventSubtags && eventTags && tags && subtags && (
        <>
          <div>
            <Divider style={{ marginTop: 20 }} />
            <MultiSelect
              style={{ marginTop: 20 }}
              label={"Event Tags"}
              value={tags}
              onChange={(values) => {
                setTags(values);
                setEditingEvent(
                  produce((draft) => {
                    let newTags = [];
                    for (const tag of values) {
                      for (const eventTag of eventTags) {
                        if (String(eventTag.id) === tag) newTags.push(eventTag);
                      }
                    }
                    let newSubtags = [];
                    for (const tag of values) {
                      for (const subTag of subtags) {
                        const original = eventSubtags.find(
                          (item) => String(item.id) === subTag
                        );
                        if (original && String(original.event_tag.id) === tag) {
                          newSubtags.push(original);
                        }
                      }
                    }
                    draft.event_tags = newTags;
                    draft.event_subtags = newSubtags;
                  })
                );
                if (values.length === 0) {
                  setSubtags([]);
                }
              }}
              data={buildLabels(eventTags)}
              clearable
              searchable
              nothingFound="Nothing found"
            />
            {filteredSubtags && (
              <MultiSelect
                style={{ marginTop: 20 }}
                value={subtags}
                onChange={(values) => {
                  setSubtags(values);
                  setEditingEvent(
                    produce((draft) => {
                      let newTags = [];
                      for (const tag of values) {
                        for (const eventSubtag of eventSubtags) {
                          if (String(eventSubtag.id) === tag)
                            newTags.push(eventSubtag);
                        }
                      }
                      draft.event_subtags = newTags;
                    })
                  );
                }}
                data={filteredSubtags}
                disabled={tags.length > 0 ? false : true}
                clearable
                searchable
                nothingFound="Nothing found"
              />
            )}
          </div>
        </>
      )}
      {error.message !== "" && (
        <Alert title="!!!!!" color={error.color}>
          {error.message}
        </Alert>
      )}
      <div
        style={{
          marginTop: 10,
          marginBottom: 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <EditButton name={"Save"} onEdit={() => save()} />
      </div>
    </Tabs.Panel>
  );
}
