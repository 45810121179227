import {
  Box,
  Button,
  Group,
  Modal,
  FileButton,
  Select,
  NumberInput,
  LoadingOverlay,
} from "@mantine/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { postMethod } from "../../../utilities/fetchMethod";
import { eventStatusType, ProfilesInfo } from "../../../utilities/models";

interface Props {
  onClose: () => void;
  onRefresh: () => void;
  profileIdList: number[];
}
export interface sendBatchEmailResult {
  success: boolean;
  message?: string;
  successfulEmails: BatchResult[];
  failedEmails: BatchResult[];
}


export interface BatchResult {
  success: boolean;
  count?: number;
  error?: string;
}
export default function SendEmailToUserProfile(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [doubleConfirm, setDoubleConfirm] = useState(false);
  const [templates, setTemplates] = useState<number | undefined>();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState({ color: "", message: "" });

  //-------------------------------------------------------------------------------------------
  // calling apis
  //-------------------------------------------------------------------------------------------

  async function sendEmail() {
    setLoading(true);
    setDisabled(false);
    setError({ color: "", message: "" });

    if (props.profileIdList.length==0 || !templates) return;

    const body = {
      profileIds: props.profileIdList,
      templates: templates,
    };

    const _postMethod = await postMethod(body);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/profile/sendEmailToUserByTemplatesByProfiles`,
      _postMethod
    );
    if(!res.ok){
      
    }
    const result:sendBatchEmailResult = await res.json();

    if ( result.success) {
      if ( result.failedEmails.length<=0) {
        setError({ color: "green", message: `Email sent!` });
        setLoading(false);

        props.onClose();
        return;
       
      } else {
       const failedEmailsErrorString = result.failedEmails.map(x=>x.error)
        
        setError({
          color: "red",
          message: failedEmailsErrorString.toString(),
        });
      }
    } else {
      setError({ color: "red", message: result.message??""});
      setLoading(false);
    }
  }




  //-------------------------------------------------------------------------------------------
  // double Confirm Modal
  //-------------------------------------------------------------------------------------------

  interface DoubleConfirmProps {
    onClose: () => void;
    confirm: () => void;
    templates: number;
    msg?: string;
    profilesId: number[];
  }

  function DoubleConfirmModal(props: DoubleConfirmProps) {
    return (
      <>
        <Modal
          opened={true}
          onClose={props.onClose}
          overlayColor={"grey"}
          overlayOpacity={0.55}
          overlayBlur={3}
          overflow="inside"
          title={`Double Confirm !!!`}
        >
          <Box sx={{ maxWidth: 400 }} mx="auto">
            {props.profilesId && (
              <div
                style={{
                  margin: 10,
                  color: "black",
                  fontSize: 20,
                  fontWeight: 200,
                }}
              >
                You are now going to send email to profiles {" "}
                <span style={{ color: "red", fontWeight: "900" }}>
                  {props.profilesId.toString()}
                </span>{" "}
                using templates{" "}
                <span style={{ color: "red", fontWeight: "900" }}>
                  {props.templates}
                </span>
              </div>
            )}
            <Group style={{ width: "100%", justifyContent: "center" }}>
              <Button
                color="red"
                onClick={() => {
                  props.confirm();
                  props.onClose();
                }}
                style={{ marginLeft: 10 }}
              >
                YES
              </Button>

              <Button
                color="green"
                onClick={props.onClose}
                style={{ marginLeft: 10 }}
              >
                NO
              </Button>
            </Group>
          </Box>
        </Modal>
      </>
    );
  }

  return (
    <>
      <LoadingOverlay
        visible={loading}
        overlayBlur={2}
        style={{ position: "fixed" }}
      />
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Send email to profile`}
      >
        <Box sx={{ maxWidth: 400 }} mx="auto">
          {doubleConfirm && templates && (
            <DoubleConfirmModal
              onClose={() => setDoubleConfirm(false)}
              confirm={() => {
                sendEmail();
              }}
              templates={templates}
              profilesId={
                props.profileIdList
              }
            />
          )}
        
          <NumberInput
            style={{ marginTop: 20 }}
            placeholder={"Template ID"}
            label={"Please input the template you are going to use"}
            withAsterisk
            value={templates}
            min={0}
            onChange={(value) => {
              setTemplates(value);
              if(value)
                {setDisabled(false)}
              else{setDisabled(true)}
            }}
          />
          {error.message && (
            <div style={{ color: error.color, marginTop: 20 }}>
              Error: {error.message} <br /> Please try again.
            </div>
          )}
          <Group
            style={{ width: "100%", justifyContent: "flex-end", marginTop: 20 }}
          >
            <Button
              disabled={disabled}
              color="green"
              onClick={() => {
                setDoubleConfirm(true);
              }}
              style={{ marginLeft: 10 }}
            >
              Submit
            </Button>
          </Group>
        </Box>
      </Modal>
    </>
  );
}
