//-------------------------------------------------------------------------------------------
// Dto
//-------------------------------------------------------------------------------------------

export interface LoginDto {
  phone_number: string;
  password: string;
}

export interface CreateEventDto {
  event_name: string;
  event_location: string;
  event_start: Date;
  event_end: Date;
  info: string;
  profile: number;
}

//-------------------------------------------------------------------------------------------
// User
//-------------------------------------------------------------------------------------------

export interface UserInfo {
  id: number;
  email: string;
  phone_location: Phone_locations;
  phone_number: string;
  active: boolean;
  disable: boolean;
  super_admin: boolean;
  allow_chatroom_message: boolean;
  allow_event_message: boolean;
  allow_loop_message: boolean;
  created_at: string;
  last_login: string;
  primary_profile_id: number;
  updated_at: string;
  password: string;
  profile_top_limit: number;
}

export const initialUserinfo: UserInfo = {
  active: true,
  disable: false,
  allow_chatroom_message: false,
  allow_event_message: false,
  allow_loop_message: false,
  created_at: "new Date()",
  email: "example@gmail.com",
  id: 0,
  last_login: "new Date()",
  password: "123456",
  phone_location: {
    active: true,
    created_at: "",
    delete_time: null,
    id: 0,
    location: "",
    location_number: "",
    updated_at: "",
  },
  profile_top_limit: 0,
  phone_number: "12345678",
  primary_profile_id: 0,
  super_admin: false,
  updated_at: "",
};

export interface CorpInfo {
  corporate_name: string;
  top_limit: number;
  active: boolean;
  id: number;
  delete_time: string;
  created_at: string;
  updated_at: string;
  allowStaffEditProfile: boolean;
}

export const initialCorpinfo = {
  active: true,
  corporate_name: "",
  top_limit: 0,
  created_at: "",
  delete_time: "",
  id: -1,
  updated_at: "",
  allowStaffEditProfile: false,
};
export interface CorpInfoProps {
  corpInfo: CorpInfo;
}

export interface CorpInfoPropsArray {
  corpInfo: CorpInfo[];
}
export interface UserInfoPropsArray {
  userInfo: UserInfo[];
}

export interface UserInfoProps {
  userInfo: UserInfo;
}

export interface Phone_locations {
  active: boolean;
  created_at: string;
  delete_time: null;
  id: number;
  location: string;
  location_number: string;
  updated_at: string;
}
//-------------------------------------------------------------------------------------------
// Profiles
//-------------------------------------------------------------------------------------------
export interface ProfilesInfo {
  profile: {
    id: number;
    created_at: string;
    updated_at: string;
    user: UserInfo | null;
    admin: boolean;
    corporate: {
      id: number;
      corporate_name: "";
    } | null;
    about_me: string;
    icon_name: string;
    icon_type: string;
    icon_url?: string | null;
    banner_name: string;
    banner_type: string;
    banner_url?: string | null;
    profile_salute: string;
    profile_first_name: string;
    profile_last_name: string;
    profile_email: string;
    phone_location: Phone_locations;
    phone_number: string;
    company_name: string;
    profile_title: string;
    profile_location: string;
    active: boolean;
    public: boolean;
    phone_public: boolean;
    email_public: boolean;
    info_public: boolean;
    location_public: boolean;
    profile_type: string;
    prefer_exchange: boolean;
    allow_listing: boolean;
    deleted_time: string;
    disable: boolean;
    contact_message: string;
    username: string;
    corporate_invitation_link: string;
    corporate_invitation_expire: string;
    representative_order: number;
    representative_to: null;
    device: any[];
    eventSection: {
      id: number;
      profile: number;
      created_at: string;
      updated_at: string;
      event_name: string;
      event_location: string;
      about_us: string;
      status: string;
      code: string;
      topEvent: boolean;
      owner_profile: {
        id: number;
        user: number;
        admin: boolean;
        corporate: null;
        icon_url: string;
        profile_first_name: string;
        profile_last_name: string;
        active: boolean;
        public: boolean;
        phone_public: boolean;
        email_public: boolean;
        info_public: boolean;
        location_public: boolean;
        profile_type: string;
        prefer_exchange: boolean;
        allow_listing: boolean;
        deleted_time: null;
        disable: boolean;
        contact_message: string;
        username: string;
        corporate_invitation_link: string;
        corporate_invitation_expire: string;
        representative_to: string;
        representative_order: number;
        eventSection: null;
      };
    };
  };
  jobtags: {
    id: number;
    job_subtag: string;
    jobtag: {
      id: number;
      job_tag: string;
    };
  }[];

  links: {
    active: boolean;
    id: number;
    social_link: string;
    link_title: string;
    icon_url: string;
    custom: boolean;
    socialOrdering: number;
    customOrdering: number;
  }[];

  interestTags: { id: number; interest: string }[];
  interestSubTags: {
    id: number;
    interest_tag: {
      id: number;
      interest: string;
    };
    interest_subtag: string;
  }[];
  gallery: {
    active: boolean;
    id: number;
    picture_url: string;
    resized_url: string;
    description: string;
    link: string;
  }[];
  profile_sections: {
    id: number;
    type: string;
    section_title: string;
    section_info: string;
  }[];
  products: {
    active: boolean;
    id: number;
    picture_url: string;
    resized_url: string;
    description: string;
    link: string;
  }[];
  profile_representative: {
    id: number;
    icon_url: string;
    profile_first_name: string;
    profile_last_name: string;
    profile_title: string;
    username: string;
    corporate: {
      id: number;
    };
  }[];
  event_dates: {
    date: Date | string;
    start_time: Date | string;
    end_time: Date | string;
  }[];
  event_tags: EventTags[];
  event_subtags: EventSubtags[];
}

export const initialProfileinfo: ProfilesInfo = {
  profile: {
    id: 0,
    created_at: "",
    updated_at: "",
    user: null,
    admin: false,
    corporate: {
      id: 0,
      corporate_name: "",
    },
    about_me: "",
    icon_name: "",
    icon_type: "",
    icon_url: "",
    banner_name: "",
    banner_type: "",
    banner_url: "",
    profile_salute: "",
    profile_first_name: "",
    profile_last_name: "",
    profile_email: "",
    phone_location: {
      active: true,
      created_at: "",
      delete_time: null,
      id: 0,
      location: "",
      location_number: "",
      updated_at: "",
    },
    phone_number: "",
    company_name: "",
    profile_title: "",
    profile_location: "",
    active: false,
    public: false,
    phone_public: false,
    email_public: false,
    info_public: false,
    location_public: false,
    profile_type: "",
    prefer_exchange: false,
    allow_listing: false,
    deleted_time: "",
    disable: false,
    contact_message: "",
    username: "",
    corporate_invitation_link: "",
    corporate_invitation_expire: "",
    representative_order: 0,
    representative_to: null,
    device: [],
    eventSection: {
      id: 0,
      profile: 0,
      created_at: "",
      updated_at: "",
      event_name: "",
      event_location: "",
      about_us: "",
      status: "",
      topEvent: false,
      code: "",
      owner_profile: {
        id: 0,
        user: 1,
        admin: false,
        corporate: null,
        icon_url: "",
        profile_first_name: "",
        profile_last_name: "",
        active: false,
        public: false,
        phone_public: false,
        email_public: false,
        info_public: false,
        location_public: false,
        profile_type: "",
        prefer_exchange: false,
        allow_listing: false,
        deleted_time: null,
        disable: false,
        contact_message: "",
        username: "",
        corporate_invitation_link: "",
        corporate_invitation_expire: "",
        representative_to: "",
        representative_order: 0,
        eventSection: null,
      },
    },
  },
  jobtags: [
    {
      id: 0,
      job_subtag: "",
      jobtag: {
        id: 0,
        job_tag: "",
      },
    },
  ],
  links: [
    {
      active: true,
      id: 0,
      social_link: "",
      link_title: "",
      icon_url: "",
      custom: false,
      socialOrdering: 0,
      customOrdering: 0,
    },
  ],
  interestTags: [{ id: 0, interest: "" }],
  interestSubTags: [
    {
      id: 0,
      interest_tag: {
        id: 0,
        interest: "",
      },
      interest_subtag: "",
    },
  ],
  gallery: [
    {
      active: true,
      id: 0,
      picture_url: "",
      resized_url: "",
      description: "",
      link: "",
    },
  ],
  profile_sections: [
    {
      id: 0,
      type: "phone",
      section_title: "",
      section_info: "",
    },
  ],
  products: [
    {
      active: true,
      id: 0,
      picture_url: "",
      resized_url: "",
      description: "",
      link: "",
    },
  ],
  profile_representative: [
    {
      id: 0,
      icon_url: "",
      profile_first_name: "",
      profile_last_name: "",
      profile_title: "",
      username: "",
      corporate: { id: 0 },
    },
  ],
  event_dates: [
    {
      date: new Date(),
      start_time: new Date(),
      end_time: new Date(),
    },
  ],
  event_tags: [
    {
      id: 0,
      event_tag: "",
    },
  ],
  event_subtags: [
    {
      id: 0,
      event_subtag: "",
      event_tag: {
        id: 0,
        event_tag: "",
      },
    },
  ],
};
export interface ProfileInfoPropsArray {
  profileInfo: ProfilesInfo[];
}

type DashboardTypes = "profiles" | "corporateMainProfiles";

export interface ProfileInfoProps {
  profileInfo: ProfilesInfoWithoutPopulated;
  type?: DashboardTypes;
}

export interface ProfilesInfoWithoutPopulated {
  profile: {
    id: number;
    created_at: string;
    updated_at: string;
    user: UserInfo | null;
    admin: boolean;
    corporate: {
      id: number;
      corporate_name: "";
    } | null;
    about_me: string;
    icon_name: string;
    icon_type: string;
    icon_url?: string | null;
    banner_name: string;
    banner_type: string;
    banner_url?: string | null;
    profile_salute: string;
    profile_first_name: string;
    profile_last_name: string;
    profile_email: string;
    phone_location: Phone_locations;
    phone_number: string;
    company_name: string;
    profile_title: string;
    profile_location: string;
    active: boolean;
    public: boolean;
    phone_public: boolean;
    email_public: boolean;
    info_public: boolean;
    location_public: boolean;
    profile_type: string;
    prefer_exchange: boolean;
    allow_listing: boolean;
    deleted_time: string;
    disable: boolean;
    contact_message: string;
    username: string;
    corporate_invitation_link: string;
    corporate_invitation_expire: string;
    representative_order: number;
    representative_to: null;
    device: any[];
  };
}

//-------------------------------------------------------------------------------------------
// Tags
//-------------------------------------------------------------------------------------------

export interface EditTags {
  head: string;
  AObj:
    | {
        id: number;
        action: string;
        active: boolean;
        name: string;
        location: string;
        location_number: string;
        problem: string;
        status: string;
        event_tag: string;
        interest: string;
        interest_tag: string;
        interest_subtag: string;
        job_tag: string;
        jobtag: string;
        job_subtag: string;
      }
    | any;
}

export interface ProfileSection {
  id: number;
  icon_url: string;
  profile_first_name: string;
  profile_last_name: string;
  profile_title: string;
  username: string;
  corporate: { id: 0 };
}

export interface OwnerProfile {
  id: number;
  user: number;
  admin: boolean;
  corporate: null;
  icon_url: string;
  profile_first_name: string;
  profile_last_name: string;
  active: boolean;
  public: boolean;
  phone_public: boolean;
  email_public: boolean;
  info_public: boolean;
  location_public: boolean;
  profile_type: string;
  prefer_exchange: boolean;
  allow_listing: boolean;
  deleted_time: null;
  disable: boolean;
  contact_message: string;
  username: string;
  corporate_invitation_link: string;
  corporate_invitation_expire: string;
  representative_to: string;
  representative_order: number;
  eventSection: null;
}

export interface EventTags {
  id: number;
  event_tag: string;
}

export interface EventSubtags {
  id: number;
  event_subtag: string;
  event_tag: EventTags;
}

export interface EventSection {
  id: number;
  profile: number;
  created_at: string;
  updated_at: string;
  event_name: string;
  event_location: string;
  event_start: string;
  event_end: string;
  about_us: string;
  status: string;
  code: string;
  owner_profile: {
    id: number;
    user: number;
    admin: boolean;
    corporate: null;
    active: boolean;
    public: boolean;
    phone_public: boolean;
    email_public: boolean;
    info_public: boolean;
    location_public: boolean;
    profile_type: string;
    prefer_exchange: boolean;
    allow_listing: boolean;
    deleted_time: null;
    disable: boolean;
    contact_message: string;
    username: string;
    corporate_invitation_link: string;
    corporate_invitation_expire: string;
    representative_to: string;
    representative_order: number;
    eventSection: null;
  };
}

export interface ProfileLinks {
  active: boolean;
  id: number;
  social_link: string;
  link_title: string;
  icon_url: string;
  custom: boolean;
  socialOrdering: number;
  customOrdering: number;
}

//-------------------------------------------------------------------------------------------
// Device
//-------------------------------------------------------------------------------------------

export const initialDeviceInfo = {
  id: 0,
  created_at: "",
  updated_at: "",
  name: "",
  device_id: "",
  profile: 0,
  active: true,
  corporates: null,
};

export interface DeviceInfo {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  device_id: string;
  profile: {
    id: number;
    username: string;
    user: {
      id: number;
      phone_number: string;
    } | null;
  } | null;
  active: boolean;
  corporates: {
    id: number;
    corporate_name: string;
  } | null;
  expired: boolean;
  modal: string | null;
  follow_primary_profile: boolean;
  active_date:Date|string|null ;
  expired_date:Date|string|null;
  active_count:number;
}

export interface EditDeviceInfo{
  active: boolean
  name: string
  profile: string|null
  expired_date: Date|string |null
  active_date: Date|string|null
  expired: boolean
  corporates: string |null
  modal: string|null
}

export interface user_phone_temp {
  id: number;
  created_at: string;
  updated_at: string;
  phone_number: string;
}

export interface DeviceInfoPropsArray {
  deviceInfo: DeviceInfo[];
}

export interface DeviceInfoProps {
  deviceInfo: DeviceInfo;
}

export interface EditProfilesInfo {
  id: number;
  admin: boolean;
  corporate: number | null;
  about_me: string;
  icon_url: string;
  banner_url: string;
  profile_salute: string;
  profile_first_name: string;
  profile_last_name: string;
  profile_email: string;
  phone_location: Phone_locations;
  phone_number: string;
  company_name: string;
  profile_title: string;
  profile_location: string;
  public: boolean;
  profile_type: string;
  prefer_exchange: boolean;
  allow_listing: boolean;
  contact_message: string;
  phone_public: boolean;
  email_public: boolean;
  info_public: boolean;
  location_public: boolean;
  username: string;
  user: UserInfo;
  active: boolean;
  disable: boolean;
}

export const profileType = {
  individual: "individual",
  corporate_main: "corporate_main",
  corporate: "corporate",
  events: "events",
};

export interface Links {
  active: boolean;
  id: number;
  social_link: string;
  link_title: string;
  icon_url: string;
  custom: boolean;
  socialOrdering: number;
  customOrdering: number;
}

export interface LinkReference {
  id: number;
  created_at: string;
  updated_at: string;
  title: string;
  icon_url: string;
  active: boolean;
}

export interface GalleryReference {
  picture_url?: File;
  resized_url: string;
  description: string;
  link: string;
}

export const initialNewGallery: GalleryReference = {
  resized_url: "",
  description: "",
  link: "",
};

export interface jobTagsReference {
  id: number;
  job_tag: string;
}

export const initialJobTags: jobTagsReference = {
  id: 0,
  job_tag: "",
};

export interface jobSubTagsReference {
  id: number;
  job_subtag: string;
  jobtag: jobTagsReference;
}

export const initialJobSubTags: jobSubTagsReference = {
  id: 0,
  job_subtag: "",
  jobtag: initialJobTags,
};

export interface InterestsTagsReference {
  id: number;
  interest: string;
}

export const initialInterestsTags: InterestsTagsReference = {
  id: 0,
  interest: "",
};

export interface InterestsSubTagsReference {
  id: number;
  interest_subtag: string;
  interest_tag: InterestsTagsReference;
}

export const initialInterestsSubTags: InterestsSubTagsReference = {
  id: 0,
  interest_subtag: "",
  interest_tag: initialInterestsTags,
};

export const usernameRegex = new RegExp("^[a-z0-9]+$", "g");

export const eventStatusType = {
  awaitingApproval: "awaitingApproval",
  approved: "approved",
  inProgress: "inProgress",
  finished: "finished",
};
