import React, { useCallback, useEffect, useState } from "react";
import { deleteMethodWithBody, getMethod } from "../../utilities/fetchMethod";
import {
  CorpInfo,
  initialProfileinfo,
  ProfileInfoProps,
  ProfilesInfo,
  ProfilesInfoWithoutPopulated,
  profileType,
} from "../../utilities/models";
import {
  Button,
  Checkbox,
  Divider,
  Group,
  Switch,
  Table,
  LoadingOverlay,
  Tooltip,
  Menu,
} from "@mantine/core";
import SearchBar from "../../Bars/SearchBar";
import CreateProfileModalBox from "../../Components/ModalBox/CreateProfileModalBox";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import {
  allCorpState,
  editingProfileState,
  selectUserListState,
  userState,
} from "../../recoil_state";
import DeleteModalBox from "../../Components/ModalBox/DeleteModalBox";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileCards from "../../Components/ProfileCards";
import SetAndDownGradeCorpMainModal from "../../Components/ModalBox/SetAndDownGradeCorpMainModal";
import InputCSV from "../../Components/ModalBox/InputCSV";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import ProfileDashboardFilterBar from "../../Bars/ProfileDashboardFilterBar";
import DisableModalBox from "../../Components/ModalBox/DisableModalBox";
import AbleModalBox from "../../Components/ModalBox/AbleModalBox";
import dayjs from "dayjs";
import ApplyModalBox from "../../Components/ModalBox/ApplyModalBox";
import SendEmailToUserProfile from "../../Components/ModalBox/Email/SendEmailToUserByProfile";
import {
  downloadCSVByCurrentData,
  escapeCSV,
} from "../../utilities/downloadCSVByCurrentData";
import ExportCSVButton from "../../Components/Buttons/ExportCSVButton";

export default function ProfileDashboard() {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------

  const [mainProfileList, setMainProfileList] = useState<
    ProfilesInfoWithoutPopulated[]
  >([]);
  const [currentProfileList, setCurrentProfileList] = useState<
    ProfilesInfoWithoutPopulated[]
  >([]);

  const navigate = useNavigate();

  const [corporateMainList, setCorporateMainList] = useState<ProfilesInfo[]>([
    initialProfileinfo,
  ]);

  const [backupCurrentProfileList, setBackupCurrentProfileList] = useState([
    initialProfileinfo,
  ]);
  const [backupCorporateMainList, setBackupCorporateMainList] = useState<
    ProfilesInfo[]
  >([initialProfileinfo]);

  const [openedCreate, setOpenedCreate] = useState(false);
  const [checked, setChecked] = useState<number[]>([]);
  const [error, setError] = useState("");
  const currentLoginUser = useRecoilValue(userState);
  const [deleteProfileName, setDeleteProfileName] = useState("");
  const [openedDelete, setOpenedDelete] = useState(false);
  const [openedDisable, setOpenedDisable] = useState(false);
  const [openedAble, setOpenedAble] = useState(false);
  const [openedEmail, setOpenedEmail] = useState(false);
  const [openedApply, setOpenedApply] = useState(false);
  const [openCorporateSettings, setOpenCorporateSettings] = useState(false);
  const [card, setCard] = useState(false);
  const [editProfileInfo, setEditProfileInfo] =
    useState<ProfilesInfoWithoutPopulated>();
  const [corpList, setCorpList] = useRecoilState(allCorpState);
  const selectUserList = useRecoilValue(selectUserListState);

  const [selectCorporateList, setSelectorCorporateList] = useState<
    { value: string; label: string }[]
  >([]);

  const resetProfileState = useResetRecoilState(editingProfileState);
  let { state } = useLocation();

  const [loading, setLoading] = useState(true);
  const [inputCsvVisible, setInputCsvVisible] = useState(false);
  const [createIsDisable, setCreateIsDisable] = useState(false);

  const [openFilters, setOpenFilters] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [allowStaffEditProfile, setAllowStaffEditProfile] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  const setLinkState = async (id: number) => {
    resetProfileState();

    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/profile/one/${encodeURI(
        String(id)
      )}`,
      _getMethod
    );

    const result = await res.json();

    navigate("/dashboard/preview", { state: result });
  };

  //build crop list
  const buildList = useCallback(async () => {
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/corporates/all`,
      _getMethod
    );

    const result: CorpInfo[] = await res.json();

    let list = [{ value: "0", label: "downgrade as individual profile" }];

    for (const company of result) {
      if (company.active) {
        const item = {
          value: company.id.toString(),
          label: company.corporate_name,
        };
        list.push(item);
      }
    }
    if (state == "superAdmin") {
      setCorpList(result);
    } else {
      const found = result.find((item) => item.id === state);

      if (found) {
        setCorpList([found]);
      }
    }
    setSelectorCorporateList(list);
  }, [state]);

  //fetch all profile
  const fetchAll = useCallback(async () => {
    setLoading(true);
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/profile/${encodeURI(
        String(state)
      )}`,
      _getMethod
    );
    const rawResult: ProfilesInfo[] = await res.json();

    //sorting for inactive profiles laying at the bottom
    const result = rawResult.sort((a, b) => {
      if (a.profile.active && !b.profile.active) {
        return -1;
      } else if (!a.profile.active && b.profile.active) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });

    buildList();

    if (state == "superAdmin") {
      setMainProfileList(result);
      const newtCurrentProfileList = result.filter(
        (item) => item.profile.profile_type != profileType.corporate_main
      );
      setCurrentProfileList(newtCurrentProfileList);

      setBackupCurrentProfileList(newtCurrentProfileList);

      const newCorporateMainList = result.filter(
        (item) => item.profile.profile_type == profileType.corporate_main
      );
      setCorporateMainList(newCorporateMainList);

      setBackupCorporateMainList(newCorporateMainList);
    } else if (state) {
      const corpId = state;

      // get allow setting
      const _getMethod = await getMethod();
      const corporateRes = await fetch(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/admin/getAllowStaffEditProfileState/${encodeURI(String(corpId))}`,
        _getMethod
      );
      const allowState = await corporateRes.json();
      setAllowStaffEditProfile(allowState.state);
      setMainProfileList(
        result.filter(
          (item) =>
            item.profile.corporate && item.profile.corporate.id == corpId
        )
      );
      const newtCurrentProfileList = result.filter(
        (item) =>
          item.profile.profile_type != profileType.corporate_main &&
          item.profile.corporate &&
          item.profile.corporate.id == corpId
      );

      setCurrentProfileList(newtCurrentProfileList);

      setBackupCurrentProfileList(newtCurrentProfileList);

      const newCorporateMainList = result.filter(
        (item) =>
          item.profile.corporate &&
          item.profile.corporate.id == corpId &&
          item.profile.active == true &&
          item.profile.profile_type == profileType.corporate_main
      );
      setCorporateMainList(newCorporateMainList);
      setBackupCorporateMainList(newCorporateMainList);
    }
    setLoading(false);
  }, [
    buildList,
    setMainProfileList,
    setCurrentProfileList,
    state,
    setLoading,
    // userList,
  ]);

  //deactivate users
  async function disableItem(item: number[]) {
    const _deleteMethod = await deleteMethodWithBody(item);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/profile/disable/disable`,
      _deleteMethod
    );
    const result = await res.json();

    if (result.message === "success") {
      fetchAll();
      setChecked([]);
      setError("");
    } else if (result.error) {
      setError(result.error);
    } else {
      setError("please try again");
    }
  }

  //activiate profile
  async function activateItem(item: number[]) {
    const _deleteMethod = await deleteMethodWithBody(item);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/profile/disable/activate`,
      _deleteMethod
    );
    const result = await res.json();
    if (result.message === "success") {
      setError("");
      fetchAll();
      setChecked([0]);
    } else if (result.error) {
      setError(result.error);
    } else {
      setError("please try again");
    }
  }

  //refresh
  async function refresh() {
    fetchAll();
  }
  const handleChange = (checked: boolean, corpId: number) => {
    // Clear the existing timeout if there is one
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    // Set new state and new timeout
    setAllowStaffEditProfile(checked);
    const newTimeoutId = setTimeout(async () => {
      // Assuming getMethod is defined and works as expected
      const _getMethod = await getMethod();
      const corporateRes = await fetch(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/admin/corporateAllowStaffEditProfileStateChange/${encodeURI(
          String(corpId)
        )}/${String(checked)}`,
        _getMethod
      );
      await corporateRes.json();
    }, 1000); // Set timeout for 1 second

    // Store the new timeout ID
    setTimeoutId(newTimeoutId);
  };
  //-------------------------------------------------------------------------------------------
  // useEffects
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    if (state == "superAdmin" || Number.isInteger(+state)) fetchAll();
  }, [state]);

  useEffect(() => {
    if (state == "superAdmin") {
      setCreateIsDisable(false);
    } else if (
      currentProfileList.filter(
        (profile) =>
          profile.profile.profile_type != profileType.corporate_main &&
          profile.profile.active
      ).length >= corpList[0].top_limit
    ) {
      setCreateIsDisable(true);
    } else {
      setCreateIsDisable(false);
    }
  }, [currentProfileList, corpList, state]);

  const profileDashboardDownloadCSVByCurrentData = useCallback(() => {
    const headers = [
      "ID",
      "Username",
      "Profile First Name",
      "Profile Last Name",
      "Created At",
      "Updated At",
      "User ID",
      "Is Admin",
      "Active",
      "Active Device",
      "Visible To Public",
      "Corporate",
    ];

    // Create CSV rows from data
    const rows = currentProfileList.map((profile) => {
      const values = [
        profile.profile.id,
        profile.profile.username,
        profile.profile.profile_first_name,
        profile.profile.profile_last_name,
        profile.profile.created_at,
        profile.profile.updated_at,
        profile.profile.user?.id,
        profile.profile.admin,
        profile.profile.active,
        profile.profile.device.length,
        profile.profile.public,
        profile.profile.corporate?.corporate_name,
      ];
      return values.map((value) => escapeCSV(value));
    });
    let fileName = "Profile_Data";
    if (state != "superAdmin") {
      const corp = corpList.find((x) => x.id == state);
      if (corp?.corporate_name) {
        fileName = corp?.corporate_name + "_" + fileName;
      }
    }
    downloadCSVByCurrentData(headers, rows, fileName);
  }, [currentProfileList]);
  //-------------------------------------------------------------------------------------------
  // setting rows & headers
  //-------------------------------------------------------------------------------------------

  //rows
  function Rows(props: ProfileInfoProps) {
    const profileInfo = props.profileInfo;
    // if(!profileInfo.profile.active)
    // return(<></>)
    return (
      <tr key={profileInfo.profile.id}>
        <td>
          {profileInfo.profile.active && (
            <Checkbox
              checked={
                checked.findIndex((id) => id === profileInfo.profile.id) > -1
                  ? true
                  : false
              }
              onChange={(event) => {
                event.currentTarget.checked
                  ? setChecked([...checked, profileInfo.profile.id!])
                  : setChecked(
                      checked.filter((id) => id !== profileInfo.profile.id)
                    );
              }}
            />
          )}
        </td>

        {/* ==================================================================================== */}
        {/* Buttons ============================================================================ */}
        {/* ==================================================================================== */}

        <td>
          <div>
            {profileInfo && profileInfo.profile.active && (
              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <Button>Menu</Button>
                </Menu.Target>

                <Menu.Dropdown>
                  {/* Edit ============================================================================ */}
                  <Menu.Label>{"Actions"}</Menu.Label>
                  {/* Edit ============================================================================ */}

                  <div onClick={() => setLinkState(profileInfo.profile.id)}>
                    <Menu.Item>Edit</Menu.Item>
                  </div>

                  {/* Set as Main profile ================================================================ */}
                  {profileInfo && profileInfo.profile.active && (
                    // currentLoginUser.super_admin &&
                    <>
                      <Menu.Item
                        onClick={() => {
                          setEditProfileInfo(profileInfo);
                          setOpenCorporateSettings(true);
                        }}
                      >
                        {profileInfo.profile.profile_type ==
                        profileType.corporate_main
                          ? "downgrade as individual"
                          : "upgrade as corp main"}
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Label>Danger zone</Menu.Label>
                      {/* Disable ============================================================================ */}
                      <Menu.Item
                        color="yellow"
                        onClick={() => {
                          if (profileInfo.profile.disable) {
                            activateItem([profileInfo.profile.id!]);
                          } else {
                            disableItem([profileInfo.profile.id!]);
                          }
                        }}
                      >
                        {profileInfo.profile.disable ? "Activate" : "Disable"}
                      </Menu.Item>
                      {/* delete ============================================================================ */}
                      <Menu.Item
                        color="red"
                        onClick={() => {
                          setChecked([profileInfo.profile.id!]);
                          setOpenedDelete(true);
                          setDeleteProfileName(profileInfo.profile.username);
                        }}
                      >
                        Delete Profile
                      </Menu.Item>
                    </>
                  )}
                </Menu.Dropdown>
              </Menu>
            )}
          </div>
        </td>
        {/* ==================================================================================== */}
        {/* profile infos =================================================================+==== */}
        {/* ==================================================================================== */}
        <td>
          {profileInfo.profile.icon_url ? (
            <img
              alt={""}
              style={{ width: 90, height: 90, borderRadius: 100 }}
              src={`${profileInfo.profile.icon_url}`}
            />
          ) : (
            <div>NA</div>
          )}
        </td>

        {profileInfo.profile.username ? (
          <td key={`username`} width={1500}>
            {profileInfo.profile.username}
          </td>
        ) : (
          <td key={`username`} width={1500}>
            <div>Na</div>
          </td>
        )}

        {profileInfo.profile.user ? (
          <td key={`${profileInfo.profile.id}-user-user`} width={1500}>
            {profileInfo.profile.user.phone_number}
          </td>
        ) : (
          <td key={`${profileInfo.profile.id}-user-user`} width={1500}>
            <div>NA</div>
          </td>
        )}

        {profileInfo.profile.device ? (
          <td key={`active_device`} width={1500}>
            {
              profileInfo.profile.device.filter((item) => {
                return item.active == true;
              }).length
            }
          </td>
        ) : (
          <td key={`corporate_active_deviceinvitation_link`} width={1500}>
            <div>0</div>
          </td>
        )}

        {profileInfo.profile.updated_at ? (
          <td key={`updated_at`} width={1500}>
            {dayjs(profileInfo.profile.updated_at).format("YYYY-MM-DD , HH:mm")}
          </td>
        ) : (
          <td key={`updated_at`} width={1500}>
            <div>NA</div>
          </td>
        )}

        {profileInfo.profile.public ? (
          <td key={`public`} width={1500}>
            Yes
          </td>
        ) : (
          <td key={`public`} width={1500}>
            <div>No</div>
          </td>
        )}

        {profileInfo.profile.corporate_invitation_link ? (
          <td key={`corporate_invitation_link`} width={1500}>
            {profileInfo.profile.corporate_invitation_link}
          </td>
        ) : (
          <td key={`corporate_invitation_link`} width={1500}>
            <div>NA</div>
          </td>
        )}
        <td key={`created_at`} width={1500}>
          {dayjs(profileInfo.profile.created_at).format("YYYY-MM-DD , HH:mm")}
        </td>
        {profileInfo.profile ? (
          <td key={`id`} width={1500}>
            {profileInfo.profile.id}
          </td>
        ) : (
          <td key={`id`} width={1500}>
            <div>NA</div>
          </td>
        )}

        {/* {array.map(([key, value]) => {
          if (
            key == "user" ||
            key == "phone_location" ||
            key == "phone_number" ||
            key == "id"
          )
            return;

          return (
            <td
              id={`${profileInfo.profile.id}-${key}-${JSON.stringify(value)}`}
              key={`${profileInfo.profile.id}-${key}-${JSON.stringify(value)}`}
              width={1500}
            >
              {value === true || value === false
                ? JSON.stringify(value)
                : typeof value === "string" && value.length > 10
                ? value.slice(0, 10) + "..."
                : Array.isArray(value)
                ? value.length
                : `${value}`}
            </td>
          );
        })} */}
      </tr>
    );
  }

  //headers
  function Headers(props: ProfileInfoProps) {
    const type = props.type;

    return (
      <tr>
        <th>
          {type === "corporateMainProfiles" && (
            <Checkbox
              checked={
                checked.length ===
                corporateMainList.filter((item) => item.profile.active === true)
                  .length
              }
              onChange={(event) => {
                event.currentTarget.checked
                  ? setChecked(
                      corporateMainList
                        .filter((item) => item.profile.active === true)
                        .map((item) => item.profile.id)
                    )
                  : setChecked([]);
              }}
            />
          )}
          {type === "profiles" && (
            <Checkbox
              checked={
                checked.length ===
                currentProfileList.filter(
                  (item) => item.profile.active === true
                ).length
              }
              onChange={(event) => {
                event.currentTarget.checked
                  ? setChecked(
                      currentProfileList
                        .filter((item) => item.profile.active === true)
                        .map((item) => item.profile.id)
                    )
                  : setChecked([]);
              }}
            />
          )}
        </th>
        <th>Edit Profile</th>
        <th>icon</th>
        <th>User Name</th>
        <th>Account number</th>
        <th>Active Device</th>
        <th>Date Modified</th>
        <th>Visible to public</th>
        <th>Invitation link</th>
        <th>Create Date</th>
        <th>ID</th>
        {/* {array.map((keys) => {
          if (
            !(
              keys == "user" ||
              keys == "phone_location" ||
              keys == "phone_number"
            )
          ) {
            return <th key={keys}>{keys}</th>;
          }
        })} */}
      </tr>
    );
  }

  //-------------------------------------------------------------------------------------------
  // components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 10,
          }}
        >
          <LoadingOverlay
            visible={loading}
            overlayBlur={2}
            style={{ position: "fixed" }}
          />
          <div style={{ fontSize: 25, fontWeight: "800" }}>
            ProfileDashboard
          </div>
          <SearchBar
            onSearch={setSearchText}
            onRemove={() => setSearchText("")}
          />
        </div>
        <div>{error}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <Group>
            <Menu shadow="md" width={200}>
              {createIsDisable ? (
                <Menu.Target>
                  <Tooltip
                    label="This corp profile is on top limit. Please contact Loop"
                    offset={20}
                    color="red"
                  >
                    <Button>Menu</Button>
                  </Tooltip>
                </Menu.Target>
              ) : (
                <Menu.Target>
                  <Button>Menu</Button>
                </Menu.Target>
              )}

              <Menu.Dropdown>
                <Menu.Label>{"Actions"}</Menu.Label>
                {/* Edit ============================================================================ */}
                {createIsDisable ? (
                  <>
                    <Menu.Item disabled>Create Profile</Menu.Item>
                    <Menu.Divider />
                    <Menu.Label>create with csv</Menu.Label>
                    <Menu.Item disabled>Upload CSV</Menu.Item>
                    <Menu.Item
                      color={"yellow"}
                      onClick={() => profileDashboardDownloadCSVByCurrentData()}
                    >
                      Download CSV By CurrentData
                    </Menu.Item>
                  </>
                ) : (
                  <>
                    <Menu.Item
                      color={"yellow"}
                      onClick={() => profileDashboardDownloadCSVByCurrentData()}
                    >
                      Download CSV By CurrentData
                    </Menu.Item>
                    <Menu.Item
                      color={"green"}
                      onClick={() => setOpenedCreate(true)}
                    >
                      Create Profile
                    </Menu.Item>

                    <>
                      <Menu.Divider />
                      <Menu.Label>create with csv</Menu.Label>
                      <Menu.Item
                        color={"green"}
                        onClick={() => {
                          setInputCsvVisible(true);
                        }}
                      >
                        Upload CSV
                      </Menu.Item>
                    </>
                  </>
                )}

                <a
                  href="/loopCreate.csv"
                  download
                  style={{ textDecoration: "none" }}
                >
                  <Menu.Item>{"Download CSV template"}</Menu.Item>
                </a>
                {!card && currentLoginUser.super_admin && (
                  <>
                    <Menu.Divider />
                    <Menu.Label>Email</Menu.Label>
                    <Menu.Item
                      color={"blue"}
                      onClick={() => {
                        if (checked.length > 0) {
                          setOpenedEmail(true);
                        }
                      }}
                    >
                      Send Email
                    </Menu.Item>{" "}
                  </>
                )}

                {!card && currentLoginUser.super_admin && (
                  <>
                    <Menu.Divider />
                    <Menu.Label>Danger zone</Menu.Label>
                    <Menu.Item
                      color={"green"}
                      onClick={() => {
                        if (checked.length > 0) {
                          setOpenedAble(true);
                        }
                      }}
                    >
                      Able
                    </Menu.Item>{" "}
                    <Menu.Item
                      color={"yellow"}
                      onClick={() => {
                        if (checked.length > 0) {
                          setOpenedDisable(true);
                        }
                      }}
                    >
                      Disable
                    </Menu.Item>
                    <Menu.Item
                      color={"red"}
                      onClick={() => {
                        if (checked.length > 0) {
                          setOpenedDelete(true);
                        }
                      }}
                    >
                      Delete
                    </Menu.Item>
                  </>
                )}
                {state !== "superAdmin" && (
                  <>
                    <Menu.Divider />
                    <Menu.Label>Apply theme</Menu.Label>
                    <Menu.Item
                      color={"rgb(255, 0, 255)"}
                      onClick={() => {
                        setOpenedApply(true);
                      }}
                    >
                      ApplyCorp Main Profile to all
                    </Menu.Item>
                  </>
                )}
              </Menu.Dropdown>
            </Menu>
            <Button
              size="xs"
              variant="subtle"
              onClick={() => setOpenFilters(!openFilters)}
              // disabled={connectProfiles.length > 0 ? false : true}
            >
              <FontAwesomeIcon icon={faFilter} size={"xl"} />
            </Button>
          </Group>
          {state != "superAdmin" && (
            <div
              style={{
                display: "flex",
                // display:"none",
                alignItems: "center",
              }}
            >
              <p>Allow Staff Edit Profile Setting : </p>
              <Switch
                style={{ marginRight: 20 }}
                onLabel={"Allow"}
                offLabel={"Not Allow"}
                checked={allowStaffEditProfile}
                onChange={(event) =>
                  handleChange(event.currentTarget.checked, state)
                }
                size={"xl"}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Switch
              style={{ marginRight: 20 }}
              onLabel={"card"}
              offLabel={"table"}
              checked={card}
              onChange={(event) => setCard(event.currentTarget.checked)}
              size={"xl"}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: 10,
            marginLeft: 15,
          }}
        >
          <div style={{ display: openFilters ? "flex" : "none" }}>
            <ProfileDashboardFilterBar
              filteredProfileData={setCurrentProfileList}
              filteredCorporateMainListData={setCorporateMainList}
              backupProfileData={backupCurrentProfileList}
              backupCorporateMainListData={backupCorporateMainList}
              searchText={searchText}
            />
          </div>
        </div>
        <Divider />
        {openedCreate && (
          <CreateProfileModalBox
            onClose={() => setOpenedCreate(false)}
            onRefresh={() => refresh()}
            selectUserList={selectUserList}
            corporate={state && typeof state === "number" ? true : false}
          />
        )}

        {inputCsvVisible && (
          <InputCSV
            onClose={() => setInputCsvVisible(false)}
            onRefresh={() => {}}
            corpId={state !== "superAdmin" ? state : null}
            superAdminCreateUser={state == "superAdmin" ? true : false}
          />
        )}
        {openedDelete && checked.length > 0 && (
          <DeleteModalBox
            onClose={() => {
              setOpenedDelete(false);
              setChecked([]);
            }}
            onRefresh={() => refresh()}
            itemArray={checked}
            link={`${process.env.REACT_APP_BACKEND_URL}/admin/profile`}
            name={deleteProfileName}
          />
        )}

        {openedDisable && checked.length > 0 && (
          <DisableModalBox
            onClose={() => {
              setOpenedDisable(false);
              setChecked([]);
            }}
            onRefresh={() => refresh()}
            itemArray={checked}
            link={`${process.env.REACT_APP_BACKEND_URL}/admin/profile/disable/disable`}
            // name={deleteProfileName}
          />
        )}
        {openedEmail && checked.length > 0 && (
          <SendEmailToUserProfile
            onClose={() => setOpenedEmail(false)}
            onRefresh={() => {}}
            profileIdList={checked}
          />
        )}
        {openedAble && checked.length > 0 && (
          <AbleModalBox
            onClose={() => {
              setOpenedDisable(false);
              setChecked([]);
            }}
            onRefresh={() => refresh()}
            itemArray={checked}
            link={`${process.env.REACT_APP_BACKEND_URL}/admin/profile/disable/activate`}
            // name={deleteProfileName}
          />
        )}
        {openedApply && state !== "superAdmin" && (
          <ApplyModalBox
            onClose={() => {
              setOpenedApply(false);
            }}
            onRefresh={() => refresh()}
            corpId={state}
            link={`${process.env.REACT_APP_BACKEND_URL}/admin/applyCorpMainItemToAllStaff`}
          />
        )}
        {openCorporateSettings &&
          editProfileInfo &&
          selectCorporateList.length > 0 && (
            <SetAndDownGradeCorpMainModal
              onClose={() => {
                setOpenCorporateSettings(false);
              }}
              onRefresh={() => refresh()}
              selectCorporateList={selectCorporateList}
              editProfile={editProfileInfo}
            />
          )}
        <div style={{ marginBlock: 10, fontWeight: "600" }}>
          Corporate Main Profiles
        </div>
        <div style={{ overflow: "auto", marginBottom: 30 }}>
          {!card && (
            <Table>
              <thead>
                {mainProfileList[0] && (
                  <Headers
                    profileInfo={mainProfileList[0]}
                    type={"corporateMainProfiles"}
                  />
                )}
              </thead>
              <tbody>
                {corporateMainList &&
                  corporateMainList.map((item) => (
                    <Rows key={item.profile.id} profileInfo={item} />
                  ))}
              </tbody>
            </Table>
          )}
          {card && (
            <>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {corporateMainList &&
                  corporateMainList.map((item) => (
                    <>
                      {item.profile.active ? (
                        state && typeof state === "number" ? (
                          item.profile.profile_type !==
                          profileType.corporate_main ? (
                            <ProfileCards
                              key={item.profile.id}
                              profileInfo={item}
                            />
                          ) : (
                            <div
                              onClick={() => {
                                setLinkState(item.profile.id);
                              }}
                            >
                              <ProfileCards
                                key={item.profile.id}
                                profileInfo={item}
                              />
                            </div>
                          )
                        ) : (
                          <div
                            onClick={() => {
                              setLinkState(item.profile.id);
                            }}
                          >
                            <ProfileCards
                              key={item.profile.id}
                              profileInfo={item}
                            />
                          </div>
                        )
                      ) : (
                        <ProfileCards
                          key={item.profile.id}
                          profileInfo={item}
                        />
                      )}
                    </>
                  ))}
              </div>
            </>
          )}
        </div>
        <Divider style={{ borderColor: "black", borderWidth: 5 }} />
        <div style={{ marginBlock: 10, fontWeight: "600" }}>Profiles</div>
        <div style={{ overflow: "auto" }}>
          {!card && (
            <Table>
              <thead>
                {mainProfileList[0] && (
                  <Headers profileInfo={mainProfileList[0]} type={"profiles"} />
                )}
              </thead>
              <tbody>
                {currentProfileList &&
                  currentProfileList.map((item) => (
                    <Rows key={item.profile.id} profileInfo={item} />
                  ))}
              </tbody>
            </Table>
          )}
          {card && (
            <>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {currentProfileList &&
                  currentProfileList.map((item) => (
                    <>
                      {item.profile.active ? (
                        state && typeof state === "number" ? (
                          item.profile.profile_type !==
                          profileType.corporate_main ? (
                            <ProfileCards
                              key={item.profile.id}
                              profileInfo={item}
                            />
                          ) : (
                            <div
                              onClick={() => {
                                setLinkState(item.profile.id);
                              }}
                            >
                              <ProfileCards
                                key={item.profile.id}
                                profileInfo={item}
                              />
                            </div>
                          )
                        ) : (
                          <div
                            onClick={() => {
                              setLinkState(item.profile.id);
                            }}
                          >
                            <ProfileCards
                              key={item.profile.id}
                              profileInfo={item}
                            />
                          </div>
                        )
                      ) : (
                        <ProfileCards
                          key={item.profile.id}
                          profileInfo={item}
                        />
                      )}
                    </>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
