import { Avatar, Group, Select, Text, TextInput } from "@mantine/core";
import produce from "immer";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { editingProfileState } from "../recoil_state";
import {
  deleteMethodWithBody,
  getMethod,
  patchMethod,
  patchMethodwithFormData,
} from "../utilities/fetchMethod";
import { LinkReference, Links } from "../utilities/models";
import DeleteButton from "./Buttons/DeleteButton";
import EditButton from "./Buttons/EditButton";
import ImageViewAndEdit from "./ImageViewAndEdit";

interface Props {
  fetchUrl: string;
  link: Links;
  reference: LinkReference[];
  buildReference?: { value: string; label: string; image: string }[];
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
}

export default function SocialLinkCard({
  fetchUrl,
  link,
  reference,
  buildReference,
}: Props) {
  //-------------------------------------------------------------------------------------------
  // setting
  //-------------------------------------------------------------------------------------------

  const [editingProfile, setEditingProfile] =
    useRecoilState(editingProfileState);
  const [title, setTitle] = useState(link.link_title);
  const [url, setUrl] = useState(link.social_link);
  const [iconUrl, setIconUrl] = useState(link.icon_url);

  const [selectedSocialLink, setSelectedSocialLink] = useState<string | null>(
    reference
      ? reference.filter((item) => item.icon_url == link.icon_url).length !== 1
        ? "-1"
        : String(reference.find((item) => item.icon_url == link.icon_url)?.id)
      : "-1"
  );
  const [croppedIcon, setCroppedIcon] = useState<string>();
  const imageRef = useRef<any>();

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  async function deleteSocialIcon() {
    const _deleteMethod = await deleteMethodWithBody([{ id: link.id }]);

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/profiles/links`,
      _deleteMethod
    );

    const result = await res.json();

    //if success
    if (result.message == "success") {
      //if success

      const _getMethod = await getMethod();
      // get the profile result
      const res = await fetch(fetchUrl, _getMethod);
      const profileResult = await res.json();
      if (res.ok) {
        setEditingProfile(profileResult);
        window.history.replaceState(
          { key: window.history.state.key, usr: editingProfile },
          ""
        );
      } else {
        console.log("Delete Social Icon Error");
      }
    }
  }

  async function save() {
    let result;

    //no photos
    if (!croppedIcon) {
      let body = {
        social_link: url,
        link_title: title,
        icon_url: iconUrl,
        display: link.custom ? "list" : "grid",
      };

      const _patchMethod = await patchMethod(body);
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/profiles/links/${encodeURI(
          String(link.id)
        )}`,
        _patchMethod
      );
      result = await res.json();

      //if have photos
    } else {
      const data = new FormData();
      const blob = await fetch(croppedIcon).then((r) => r.blob());
      data.append("file", blob);
      data.append("link_title", title);
      data.append("social_link", url);
      data.append("display", link.custom ? "list" : "grid");

      const _patchMethod = await patchMethodwithFormData(data);
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/profiles/links/${encodeURI(
          String(link.id)
        )}`,
        _patchMethod
      );
      result = await res.json();
    }

    //if success
    if (result.message == "success") {
      const _getMethod = await getMethod();
      // get the profile result
      const res = await fetch(fetchUrl, _getMethod);
      const profileResult = await res.json();

      if (res.ok) {
        setEditingProfile(profileResult);
        window.history.replaceState(
          { key: window.history.state.key, usr: profileResult },
          ""
        );
        if (croppedIcon != undefined) {
          setCroppedIcon(undefined);
          imageRef.current.removeImage();
        }
      } else {
        console.log("SocialLinkCard api error 1");
      }
    } else {
      console.log("SocialLinkCard api error 2");
    }
  }

  //-------------------------------------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    if (selectedSocialLink && selectedSocialLink != "-1" && reference) {
      const newIcon = reference.find(
        (item) => item.id === parseInt(selectedSocialLink)
      );
      if (newIcon) {
        setIconUrl(newIcon.icon_url);
        setEditingProfile(
          produce((draft) => {
            const index = draft.links.findIndex(
              (item) => item.id === link.id
            );
            if (index > 0) {
              draft.links[index].icon_url = newIcon.icon_url
            }
          })
        );
      }
    }
  }, [selectedSocialLink]);

  useEffect(() => {
    setIconUrl(link.icon_url);
    setTitle(link.link_title);
    setUrl(link.social_link);
  }, [link]);

  //-------------------------------------------------------------------------------------------
  // components
  //-------------------------------------------------------------------------------------------

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, description, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <Avatar src={image} />
          <div>
            <Text size="sm">{label}</Text>
          </div>
        </Group>
      </div>
    )
  );

  return (
    <div
      style={{
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderColor: "grey",
        border: "solid",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <img
          style={{ height: 45, width: 45, paddingRight: 20 }}
          src={iconUrl}
        />

        <div>
          {buildReference && (
            <Select
              data={buildReference}
              itemComponent={SelectItem}
              label="Social icons"
              placeholder="Select Social icons"
              searchable
              nothingFound="No options"
              value={selectedSocialLink}
              onChange={(value) => {
                setSelectedSocialLink(value);
              }}
            />
          )}
          {selectedSocialLink == "-1" && (
            <div>
              <ImageViewAndEdit
                type={"icon"}
                title={"profile icon"}
                onRemove={() => {}}
                imageBase64={(file) => setCroppedIcon(file)}
                imageFile={() => {}}
                ref={imageRef}
              />
            </div>
          )}

          <TextInput
            label={"Title"}
            value={title}
            onChange={(event) => {
              setTitle(event.currentTarget.value);
              setEditingProfile(
                produce((draft) => {
                  const index = draft.links.findIndex(
                    (item) => item.id === link.id
                  );
                  if (index > 0) {
                    draft.links[index].link_title = event.currentTarget.value;
                  }
                })
              );
            }}
          />

          <TextInput
            label={"Links"}
            value={url}
            onChange={(event) => {
              setUrl(event.currentTarget.value);
              setEditingProfile(
                produce((draft) => {
                  const index = draft.links.findIndex(
                    (item) => item.id === link.id
                  );
                  if (index > 0) {
                    draft.links[index].social_link = event.currentTarget.value;
                  }
                })
              );
            }}
          />
        </div>
      </div>
      <div>
        <DeleteButton
          style={{ marginRight: 10 }}
          onRemove={() => deleteSocialIcon()}
        />
        <EditButton name={"save"} onEdit={() => save()} />
      </div>
    </div>
  );
}
