import React, { useCallback, useEffect, useState } from "react";
import { getMethod, patchMethod } from "../../utilities/fetchMethod";
import {
  DeviceInfo,
  DeviceInfoProps,
  ProfilesInfo,
} from "../../utilities/models";
import EditButton from "../../Components/Buttons/EditButton";
import {
  Button,
  Dialog,
  Group,
  LoadingOverlay,
  Table,
  Text,
} from "@mantine/core";
import SearchBar from "../../Bars/SearchBar";
import EditDeviceModalBox from "../../Components/ModalBox/EditDeviceModalBox";
import CreateDeviceModalBox from "../../Components/ModalBox/CreateDeviceModalBox";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { profileListDataState, userState } from "../../recoil_state";
import { useLocation } from "react-router-dom";
import Papa from "papaparse";
import InputCSV from "../../Components/ModalBox/InputCSV";
import { useDisclosure } from "@mantine/hooks";
import ExportCSVButton from "../../Components/Buttons/ExportCSVButton";
import {
  downloadCSVByCurrentData,
  escapeCSV,
} from "../../utilities/downloadCSVByCurrentData";
import dayjs, { Dayjs } from "dayjs";
export default function EventsDashboard() {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [deviceList, setDeviceList] = useState<DeviceInfo[] | null>();
  const [currentDeviceList, setCurrentDeviceList] = useState<DeviceInfo[]>();
  const [editInfo, setEditInfo] = useState<DeviceInfo>();
  const [newDeivceIds, setNewDeviceIds] = useState<object[] | null>();
  const [openedEdit, setOpenedEdit] = useState(false);
  const [openedCreate, setOpenedCreate] = useState(false);
  const [error, setError] = useState("");
  const setProfileListData = useSetRecoilState(profileListDataState);
  const user = useRecoilValue(userState);
  let { state } = useLocation();
  const [loading, setLoading] = useState(true);

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [opened, { toggle, close }] = useDisclosure(false);

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------
  console.log("currentDeviceList", currentDeviceList);

  const UserProfileSelecDtata = useCallback(
    (result: any) => {
      let data: { value: string; label: string }[] = [
        { value: "0", label: "Not a Profile" },
      ];
      for (const profile of result.filter((item: any) => item.active == true)) {
        let label = profile.username;
        let value = profile.id.toString();
        data.push({ value: value, label: label });
      }
      setProfileListData(data);
    },
    [setProfileListData]
  );

  //fetch all profile
  const fetchAllProfile = useCallback(async () => {
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/profile/forselect/${encodeURI(
        String(state)
      )}`,
      _getMethod
    );
    const result = await res.json();

    UserProfileSelecDtata(result);
  }, [UserProfileSelecDtata]);

  //fetch all device
  const fetchAll = useCallback(async () => {
    setLoading(true);
    setError("");
    await fetchAllProfile();
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/device`,
      _getMethod
    );
    const result = await res.json();
    if (result.length > 0) {
      if (state == "superAdmin") {
        setDeviceList(result);
        setCurrentDeviceList(result);
      } else if (state) {
        const corpId = state;
        setDeviceList(
          result.filter((item: DeviceInfo) => item.corporates?.id === corpId)
        );
        setCurrentDeviceList(
          result.filter((item: DeviceInfo) => item.corporates?.id === corpId)
        );
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [setDeviceList, setCurrentDeviceList, setError, state]);

  //search function
  async function search(text: string) {
    if (!deviceList) {
      return;
    }

    const searchText = text.toLowerCase();
    const filter = deviceList.filter(
      (item) =>
        item.device_id.includes(searchText) ||
        item.profile?.user?.phone_number.includes(searchText) ||
        item.profile?.username.includes(searchText) ||
        item.modal?.includes(searchText)
    );
    if (filter.length === 0) {
      setCurrentDeviceList([]);
    } else {
      setCurrentDeviceList(filter);
    }
  }

  //remove search function
  async function removeSearchText() {
    if (!deviceList) {
      return;
    }
    setCurrentDeviceList(deviceList);
  }

  //refresh
  async function refresh() {
    fetchAll();
  }

  function downloadSCV(data: any[]) {
    const csv = Papa.unparse(data);

    const csvData = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });
    const csvURL = window.URL.createObjectURL(csvData);

    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "download.csv");
    tempLink.click();
  }

  //expire button
  async function expireAndReactive(expired: boolean, deviceId: number) {
    setError("");

    if (state !== "superAdmin") {
      return;
    }

    const _patchMethod = await patchMethod({ expired: expired });
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/device/${encodeURI(
        String(deviceId)
      )}`,
      _patchMethod
    );
    const result = await res.json();
    if (result.message === "success") {
      refresh();
    } else if (result.error) {
      setError(result.error);
    } else {
      setError("Please try again");
    }
  }

  //-------------------------------------------------------------------------------------------
  // useEffects
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    fetchAll();
    fetchAllProfile();
  }, [fetchAll, fetchAllProfile]);

  const deviceDashboardDownloadCSVByCurrentData = useCallback(() => {
    if (currentDeviceList == undefined) return;
    const headers = [
      "ID",
      "Device ID",
      "Modal",
      "Expired",
      "Active",
      "profile ID",
      "User Phone Number",
      "Follow Primary Profile",
      "Corporates",
    ];

    // Create CSV rows from data
    const rows = currentDeviceList.map((device) => {
      const values = [
        device.id,
        device.device_id,
        device.modal,
        device.expired,
        device.active,
        device.profile?.id,
        device.profile?.user?.phone_number,
        device.follow_primary_profile,
        device.corporates?.corporate_name,
      ];
      return values.map((value) => escapeCSV(value));
    });
    downloadCSVByCurrentData(headers, rows, "Device_Data");
  }, [currentDeviceList]);
  //-------------------------------------------------------------------------------------------
  // setting rows & headers
  //-------------------------------------------------------------------------------------------

  //rows
  function Rows(props: DeviceInfoProps) {
    const deviceInfo = props.deviceInfo;
    const array = Object.entries(deviceInfo);
    return (
      <tr key={deviceInfo.id}>
    
        <td>
          <EditButton
            onEdit={() => {
              setEditInfo(deviceInfo);
              setOpenedEdit(true);
            }}
          />
        </td>
        {/* {array.map(([key, value]) => (
          <td key={`${deviceInfo.id}-${key}-${value}`}>
            {value === true || value === false
              ? `${value ? "YES" : "NO"}`
              : key === "profile"
              ? value
                ? value.username
                : "none"
              : value}
          </td>
        ))} */}

        <td>
          {deviceInfo.active ? (
            <span style={{ color: "green" }}>YES</span>
          ) : (
            <span style={{ color: "orange" }}>NO</span>
          )}
        </td>
        <td>
          {deviceInfo.profile ? deviceInfo.profile.username : "NOT LINKING"}
        </td>

        <td>
          {deviceInfo.profile
            ? deviceInfo.profile.user
              ? deviceInfo.profile.user.phone_number
              : "NOT LINKING"
            : "NOT LINKING"}
        </td>
        <td>
          {deviceInfo.corporates
            ? deviceInfo.corporates.corporate_name
            : "NOT A COMPANY DEVICE"}
        </td>

        <td>
          {deviceInfo.follow_primary_profile ? "FOLLOWING" : "NOT FOLLOWING"}
        </td>
        <td>{deviceInfo.modal}</td>
        <td>{deviceInfo.device_id}</td>
        <td>
          {deviceInfo.expired_date
            ? dayjs(deviceInfo.expired_date).format("YYYY/MM/DD HH:mm")
            : "Null"}
        </td>
        <td>
          {deviceInfo.active_date
            ? dayjs(deviceInfo.active_date).format("YYYY/MM/DD HH:mm")
            : "Null"}
        </td>
      
      </tr>
    );
  }

  //headers
  function Headers(props: DeviceInfoProps) {
    const array = Object.keys(props.deviceInfo);
    return (
      <tr>
        <th>Edit</th>
        <th>Active</th>
        <th>Profile</th>
        <th>User phone number</th>
        <th>Corporate</th>
        <th>Following primary profile?</th>
        <th>modal</th>
        <th>Device ID</th>
        <th>Expired Date</th>
        <th>Active Date</th>
        
      </tr>
    );
  }

  //-------------------------------------------------------------------------------------------
  // components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <LoadingOverlay
          visible={loading}
          overlayBlur={2}
          style={{ position: "fixed" }}
        />
        {openUploadModal && (
          <InputCSV
            onClose={() => setOpenUploadModal(false)}
            onRefresh={() => {
              fetchAll();
            }}
            device
          />
        )}
        <div style={{ fontSize: 25, fontWeight: "800" }}>DeviceDashboard</div>
        <SearchBar
          onSearch={(text) => search(text)}
          onRemove={() => removeSearchText()}
        />
      </div>
      {state == "superAdmin" ? (
        <Group>
          <Button
            color={"cyan"}
            onClick={() => {
              setOpenedCreate(true);
            }}
          >
            {`Create new card IDs`}
          </Button>

          {newDeivceIds && (
            <Button color={"orange"} onClick={() => downloadSCV(newDeivceIds)}>
              Download card Links just generated
            </Button>
          )}
          {/* <Button color={"cyan"} onClick={() => setOpenUploadModal(true)}>
            upload CSV
            </Button> */}
          <ExportCSVButton
            onDownload={() => deviceDashboardDownloadCSVByCurrentData()}
          />
        </Group>
      ) : (
        <Group>
          <ExportCSVButton
            onDownload={() => deviceDashboardDownloadCSVByCurrentData()}
          />
        </Group>
      )}
      {openedCreate && (
        <CreateDeviceModalBox
          onClose={() => setOpenedCreate(false)}
          onRefresh={() => refresh()}
          onsetIds={(data) => setNewDeviceIds(data)}
        />
      )}
      {deviceList && deviceList.length > 0 && (
        <>
          {openedEdit && editInfo && (
            <EditDeviceModalBox
              onClose={() => setOpenedEdit(false)}
              onRefesh={() => refresh()}
              deviceInfo={editInfo}
            />
          )}

          <Table>
            <thead>
              <Headers deviceInfo={deviceList[0]} />
            </thead>
            {currentDeviceList && currentDeviceList.length > 0 && (
              <tbody>
                {currentDeviceList.map((device) => (
                  <Rows key={device.id} deviceInfo={device} />
                ))}
              </tbody>
            )}
          </Table>
        </>
      )}
      <Dialog
        opened={opened}
        withCloseButton
        onClose={close}
        size="lg"
        radius="md"
      >
        <Text size="sm" mb="xs" weight={500}>
          {error}
        </Text>
      </Dialog>
    </>
  );
}
